import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Seo = makeShortcode("Seo");
const LineTextLine = makeShortcode("LineTextLine");
const Container = makeShortcode("Container");
const Row = makeShortcode("Row");
const Col = makeShortcode("Col");
const ImageBed = makeShortcode("ImageBed");
const Picture = makeShortcode("Picture");
const AndManyMore = makeShortcode("AndManyMore");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Seo title={props.pageContext.frontmatter.title} description={props.pageContext.frontmatter.description} mdxType="Seo" />
    <LineTextLine title={props.pageContext.frontmatter.title} mdxType="LineTextLine" />
    <section className="pb-5">
  Sharing is caring isn't that what they say. I try to be generous and return
  the kindness back that the open-source world gives us, to the people, who like
  free stuff. Please visit my{" "}
  <a href="https://unsplash.com/@andreybond/" target="_blank" rel="noopener noreferrer">
    Unsplash
  </a>{" "}
  page where you can download free pictures made by me.
  <br />
  <b>Who knows maybe they will be handy for you? Enjoy!</b>
    </section>
    <Container mdxType="Container">
  <Row mdxType="Row">
    <Col p5="true" mdxType="Col">
      <ImageBed nohover mdxType="ImageBed">
        <Picture filename="andrey-bond-unsplash-001.jpg" name={props.pageContext.frontmatter.title} modelName={props.pageContext.frontmatter.modelName} place={props.pageContext.frontmatter.place} mdxType="Picture" />
      </ImageBed>
    </Col>
    <Col p5="true" mdxType="Col">
      <ImageBed nohover mdxType="ImageBed">
        <Picture filename="andrey-bond-unsplash-002.jpg" name={props.pageContext.frontmatter.title} modelName={props.pageContext.frontmatter.modelName} place={props.pageContext.frontmatter.place} mdxType="Picture" />
      </ImageBed>
    </Col>
    <Col p5="true" mdxType="Col">
      <ImageBed nohover mdxType="ImageBed">
        <Picture filename="andrey-bond-unsplash-003.jpg" name={props.pageContext.frontmatter.title} modelName={props.pageContext.frontmatter.modelName} place={props.pageContext.frontmatter.place} mdxType="Picture" />
      </ImageBed>
    </Col>
  </Row>
  <Row mdxType="Row">
    <Col p5="true" mdxType="Col">
      <ImageBed nohover mdxType="ImageBed">
        <Picture filename="andrey-bond-unsplash-004.jpg" name={props.pageContext.frontmatter.title} modelName={props.pageContext.frontmatter.modelName} place={props.pageContext.frontmatter.place} mdxType="Picture" />
      </ImageBed>
    </Col>
    <Col p5="true" mdxType="Col">
      <ImageBed nohover mdxType="ImageBed">
        <Picture filename="andrey-bond-unsplash-005.jpg" name={props.pageContext.frontmatter.title} modelName={props.pageContext.frontmatter.modelName} place={props.pageContext.frontmatter.place} mdxType="Picture" />
      </ImageBed>
    </Col>
    <Col p5="true" mdxType="Col">
      <ImageBed nohover mdxType="ImageBed">
        <Picture filename="andrey-bond-unsplash-006.jpg" name={props.pageContext.frontmatter.title} modelName={props.pageContext.frontmatter.modelName} place={props.pageContext.frontmatter.place} mdxType="Picture" />
      </ImageBed>
    </Col>
  </Row>
    </Container>
    <AndManyMore mdxType="AndManyMore" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      